import { useHTTP, useHTTPQuery } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";

export interface InfluencerDiscoveryArchiveStatsResponseModel {
	readonly archiveId: string;
	readonly created: string;
	readonly name: string;
	readonly createdCount: number;
	readonly queryingCount: number;
	readonly errorCount: number;
	readonly matchCount: number;
	readonly unmatchCount: number;
}

export const useInfluencerDiscoveryArchiveStats = (archiveId: string) => {
	const {state} = useUserAuthState();
	const http = useHTTP(state.accessToken);

	return useHTTPQuery(["customer", archiveId], () => {
		return http<InfluencerDiscoveryArchiveStatsResponseModel>({
			method: "GET",
			url: `${API_BASE_URL}/admin/influencer-discovery/${archiveId}`
		});
	}, {
		refetchInterval: data => data && data.createdCount === 0 && data.queryingCount === 0 ? 3600000 : 1000
	});
};
