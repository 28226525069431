import React, { useCallback, useState } from "react";
import styles from "./PageTcbReports.module.scss";
import csvIcon from "src/assets/csv.svg";
import { Center, ErrorDisplay, InfiniteScroller, Loader, LoaderSize, SmartBox, Stack } from "shared/components";
import { Limit } from "src/lib/components/Limit";
import { TitleBar } from "src/lib/components/TitleBar";
import { ListTcbClaimReportsResponseModel, useTcbClaimReports } from "../api/useTcbClaimReports";
import { Container } from "src/lib/components/Container";
import { ContainerSegment } from "src/lib/components/ContainerSegment";
import { StyledTableHeader } from "src/lib/components/StyledTableHeader";
import { StyledTable } from "src/lib/components/StyledTable";
import { useDownloadTcbClaimReport } from "../api/useDownloadTcbClaimReport";
import { useTitle } from "src/lib/useTitle";

export const PageTcbReports = (): JSX.Element => {
	useTitle("TCB Claim Reports");

	const tcbClaimReports = useTcbClaimReports();

	return (
		<Limit>
			<TitleBar title="TCB Claim Reports" />

			<Stack>
				<Container>
					<SmartBox
						switchOn={tcbClaimReports}
						loading={() => <ContainerSegment><Center><Loader /></Center></ContainerSegment>}
						error={err => <ContainerSegment><ErrorDisplay problemResponse={err} /></ContainerSegment>}
						success={data => <TcbReportsTable tcbClaimReports={data.pages.flatMap(x => x.results)} />} />
				</Container>

				<InfiniteScroller forQuery={tcbClaimReports} />
			</Stack>
		</Limit>
	);
};

interface TcbReportsTableProps {
	readonly tcbClaimReports: ListTcbClaimReportsResponseModel[];
}

const TcbReportsTable = ({ tcbClaimReports }: TcbReportsTableProps): JSX.Element => {
	return (
		<StyledTable>
			<thead>
				<tr>
					<StyledTableHeader>Report Name</StyledTableHeader>
					<StyledTableHeader> </StyledTableHeader>
				</tr>
			</thead>
			<tbody>
				{
					tcbClaimReports.map(x => (
						<tr key={x.name}>
							<td>
								<div className={styles.reportName}>
									<img src={csvIcon} />
									<div>{x.name}</div>
								</div>
							</td>
							<td>
								<TcbReportDownloadButton name={x.name} />
							</td>
						</tr>
					))
				}
			</tbody>
		</StyledTable>
	);
};

interface TcbReportDownloadButtonProps {
	name: string;
}

const TcbReportDownloadButton = ({ name }: TcbReportDownloadButtonProps): JSX.Element => {
	const download = useDownloadTcbClaimReport(name);
	const [canDownload, setCanDownload] = useState(true);

	const onClick = useCallback(() => {
		if (!canDownload) {
			return;
		}

		setCanDownload(false);
		download().finally(() => setCanDownload(true));
	}, [canDownload, download]);

	if (!canDownload) {
		return <Loader size={LoaderSize.Small} />;
	}

	return (
		<a onClick={onClick}>Download</a>
	);
};
