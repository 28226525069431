import { useCSVDownload } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";

export const useDownloadTcbClaimReport = (name: string) => {
	const { state } = useUserAuthState();

	return useCSVDownload(
		name,
		`${API_BASE_URL}/admin/tcb-claim-report/${name}`,
		Promise.resolve(state.accessToken as string)
	);
};
