import React from "react";
import { Route, Routes } from "react-router-dom";
import { PageIndex } from "./features/common/pages/PageIndex";
import { PageLoginInitiate } from "./features/auth/pages/PageLoginInitiate";
import { FrameUser } from "./features/common/FrameUser";
import { FrameAuthentication } from "./features/auth/FrameAuthentication";
import { PageLoginCallback } from "./features/auth/pages/PageLoginCallback";
import { PageDashboard } from "./features/dashboard/pages/PageDashboard";
import { PageMerchants } from "./features/merchant/pages/PageMerchants";
import { PageMerchant } from "./features/merchant/pages/PageMerchant";
import { PageCustomerDetails } from "./features/merchant/pages/PageCustomerDetails";
import { PageCustomerMerge } from "./features/customer/pages/PageCustomerMerge";
import { PageUgc } from "./features/ugc/pages/PageUgc";
import { PageCustomerSearch } from "./features/customer/pages/PageCustomerSearch";
import { PageCustomer } from "./features/customer/pages/PageCustomer";
import { PageManualInvoices } from "./features/manual-invoices/pages/PageManualInvoices";
import { PageInfluencerDiscovery } from "./features/influencer-discovery/pages/PageInfluencerDiscovery";
import { PageArchive } from "./features/influencer-discovery/pages/PageArchive";
import { PageTcbReports } from "./features/tcb-reports/pages/PageTcbReports";

export const Router = (): JSX.Element => {
	return (
		<Routes>
			<Route index element={<PageIndex />} />
			<Route path="login" element={<FrameAuthentication />}>
				<Route path="initiate" element={<PageLoginInitiate />} />
				<Route path="callback" element={<PageLoginCallback />} />
			</Route>
			<Route path="dashboard" element={<FrameUser />}>
				<Route index element={<PageDashboard />} />
			</Route>
			<Route path="influencer-discovery" element={<FrameUser />}>
				<Route index element={<PageInfluencerDiscovery />} />
				<Route path="archive/:archiveId" element={<PageArchive />} />
			</Route>
			<Route path="manual-invoice" element={<FrameUser />}>
				<Route index element={<PageManualInvoices />} />
			</Route>
			<Route path="merchants" element={<FrameUser />}>
				<Route index element={<PageMerchants />} />
				<Route path=":merchantId" element={<PageMerchant />} />
				<Route path=":merchantId/customers/:customerId" element={<PageCustomerDetails />} />
			</Route>
			<Route path="customers" element={<FrameUser />}>
				<Route index element={<PageCustomerSearch />} />
				<Route path="merge" element={<PageCustomerMerge />} />
				<Route path=":emailAddress" element={<PageCustomer />} />
			</Route>
			<Route path="tcb-reports" element={<FrameUser />}>
				<Route index element={<PageTcbReports />} />
			</Route>
			<Route path="ugc" element={<FrameUser />}>
				<Route index element={<PageUgc />} />
			</Route>
		</Routes>
	);
};
