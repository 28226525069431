import React, { useMemo } from "react";
import styles from "./StatsBar.module.scss";

export interface StatsBarProps {
	readonly createdCount: number;
	readonly queryingCount: number;
	readonly errorCount: number;
	readonly matchCount: number;
	readonly unmatchCount: number;
}

export const StatsBar = (props: StatsBarProps): JSX.Element => {
	const {
		createdCount,
		queryingCount,
		errorCount,
		matchCount,
		unmatchCount: unmatchedCount
	} = props;

	const [createdWidth, queryingWidth, errorWidth, matchWidth, unmatchedWidth] = useMemo(() => {
		const total = createdCount + queryingCount + errorCount + matchCount + unmatchedCount;
		const createdWidth = (createdCount / total) * 100;
		const queryingWidth = (queryingCount / total) * 100;
		const errorWidth = (errorCount / total) * 100;
		const matchWidth = (matchCount / total) * 100;
		const unmatchedWidth = (unmatchedCount / total) * 100;
		return [createdWidth, queryingWidth, errorWidth, matchWidth, unmatchedWidth];
	}, [createdCount, queryingCount, errorCount, matchCount, unmatchedCount]);

	return (
		<div className={styles.statsBar}>
			<div className={styles.error} style={{ width: `${errorWidth}%` }}></div>
			<div className={styles.unmatched} style={{ width: `${unmatchedWidth}%` }}></div>
			<div className={styles.matched} style={{ width: `${matchWidth}%` }}></div>
			<div className={styles.querying} style={{ width: `${queryingWidth}%` }}></div>
			<div className={styles.created} style={{ width: `${createdWidth}%` }}></div>
		</div>
	);
};
