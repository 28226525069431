import { HTTPFunc, Response, defaultHTTP } from "../lib/http";

export interface RecordMinorEngagementRequestModel {
	readonly merchantId: string;
	readonly type: MinorEngagementType;
	readonly abVariant: string | null;
	readonly userUniqueId: string | null;
}

export enum MinorEngagementType {
	TcbPostCheckout = "TcbPostCheckout",
	QuickSharePageView = "QuickSharePageView",
	TcbProbableSrf = "TcbProbableSrf"
}

export const recordMinorEngagement = (apiBaseUrl: string, requestModel: RecordMinorEngagementRequestModel, httpFunc?: HTTPFunc): Promise<Response> => {
	return (httpFunc || defaultHTTP)({
		method: "POST",
		url: `${apiBaseUrl}/minor-engagement`,
		body: requestModel
	});
};
