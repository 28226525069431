import { MutationFunction, useQueryClient } from "react-query";
import { getHeaders, getProblemResponse, Problem, ProblemResponse, Response, useHTTPMutation } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";

export interface SubmitInfluencerDiscoveryArchiveResponseModel {
	readonly archiveId: string;
}

export const useSubmitInfluencerDiscoveryArchive = () => {
	const { state } = useUserAuthState();
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<SubmitInfluencerDiscoveryArchiveResponseModel>, File> = async file => {
		try {
			const response = await fetch(`${API_BASE_URL}/admin/influencer-discovery?name=${file.name}`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${state.accessToken}`
				},
				body: file
			});

			if (response.status > 299 || response.status < 200) {
				throw await getProblemResponse(response);
			}

			const body: SubmitInfluencerDiscoveryArchiveResponseModel = await response.json();
			return {
				statusCode: response.status,
				headers: getHeaders(response.headers),
				body
			};
		} catch (err) {
			if ((err as ProblemResponse).body) {
				throw err;
			}

			const problem: Problem = {
				type: "NetworkError",
				detail: "A network failure caused the operation to fail."
			};

			throw {
				statusCode: 0,
				body: problem
			};
		}
	};

	return useHTTPMutation(mutFunc, {
		onSuccess: () => queryClient.invalidateQueries(["influencerDiscoveryArchives"])
	});
};
