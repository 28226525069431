import { InfiniteQueryPageContext, PaginatedResponseModel, useHTTP, useHTTPQueryWithCursor } from "shared/lib/http";
import { useUserAuthState } from "src/features/auth/UserAuthState";
import { API_BASE_URL } from "src/lib/config";

export interface ListInfluencerDiscoveryArchivesResponseModel {
	readonly archiveId: string;
	readonly created: Date;
	readonly name: string;
	readonly complete: boolean;
}

export const useInfluencerDiscoveryArchives = () => {
	const { state } = useUserAuthState();
	const http = useHTTP(state.accessToken);

	return useHTTPQueryWithCursor(["influencerDiscoveryArchives"], ({ pageParam = 0 }: InfiniteQueryPageContext) => {
		return http<PaginatedResponseModel<ListInfluencerDiscoveryArchivesResponseModel>>({
			method: "GET",
			url: `${API_BASE_URL}/admin/influencer-discovery?cursor=${pageParam}`
		});
	});
};
