import { useCallback } from "react";

export const useCSVDownload = (fileName: string, url: string, accessToken: Promise<string>) => {
	return useCallback(async () => {
		const res = await fetch(url, {
			method: "GET",
			headers: {
				"Accept": "text/csv",
				"Authorization": `Bearer ${await accessToken}`
			}
		});

		const blob = await res.blob();
		const objectURL = window.URL.createObjectURL(blob);
		const anchor = document.createElement("a");
		anchor.href = objectURL;
		anchor.download = fileName;
		document.body.appendChild(anchor);
		anchor.click();
	}, [url, accessToken]);
};
