import React from "react";
import styles from "./LiveIndicator.module.scss";

export const LiveIndicator = (): JSX.Element => {
	return (
		<div className={styles.liveIndicator}>
			<div>
				<div></div>
				<div>LIVE</div>
			</div>
		</div>
	);
};
