import { useMemo } from "react";
import { ShareMechanism } from "../lib/domain";

export interface UseShareActionResult {
	action: () => void;
	shareMechanism: ShareMechanism;
}

export const useShareAction = (link: string | undefined): UseShareActionResult => {
	return useMemo(() => {
		if (!link) {
			return {
				action: () => undefined,
				shareMechanism: ShareMechanism.CopyLink
			};
		}

		if (!navigator.canShare || !navigator.canShare({ url: link })) {
			return {
				action: () => navigator.clipboard.writeText(link),
				shareMechanism: ShareMechanism.CopyLink
			};
		}

		return {
			action: () => navigator.share({ url: link }),
			shareMechanism: ShareMechanism.Native
		};
	}, [link]);
};
