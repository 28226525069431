import React from "react";
import styles from "./ContainerListButton.module.scss";
import { OverflowEllipsis } from "shared/components";
import { Link } from "react-router-dom";

export interface ContainerListButtonProps {
	text: string;
	icon?: string;
	link?: string;
	newTab?: boolean;
	disabled?: boolean;
	onClick?: () => void;
}

export const ContainerListButton = (props: ContainerListButtonProps): JSX.Element => {
	const {
		text,
		icon,
		link,
		newTab,
		disabled,
		onClick
	} = props;

	const classNames = [ styles.containerListButton ];
	if (disabled) {
		classNames.push(styles.disabled);
	}

	const onClickEnriched = (e: React.MouseEvent<HTMLAnchorElement>) => {
		if (disabled || !onClick || link) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();
		onClick();
	};

	return (
		<Link to={link || ""} target={newTab ? "_blank" : undefined} className={classNames.join(" ")} onClick={onClickEnriched}>
			{icon && <img src={icon} />}
			<OverflowEllipsis>{text}</OverflowEllipsis>
		</Link>
	);
};
