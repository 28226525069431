import React from "react";
import { useParams } from "react-router-dom";
import { Limit } from "src/lib/components/Limit";
import { TitleBar } from "src/lib/components/TitleBar";
import { InfluencerDiscoveryArchiveStatsResponseModel, useInfluencerDiscoveryArchiveStats } from "../api/useInfluencerDiscoveryArchiveStats";
import { Center, ErrorDisplay, Loader, SmartBox, Stack } from "shared/components";
import { ContainerSegment } from "src/lib/components/ContainerSegment";
import { Container } from "src/lib/components/Container";
import { ContainerListItem } from "src/lib/components/ContainerListItem";
import dayjs from "dayjs";
import { LiveIndicator } from "src/lib/components/LiveIndicator";
import { StatsBar } from "../components/StatsBar";
import { ContainerListButton } from "src/lib/components/ContainerListButton";
import { API_BASE_URL } from "src/lib/config";
import { useTitle } from "src/lib/useTitle";

export const PageArchive = (): JSX.Element => {
	const params = useParams();
	const archiveId = params.archiveId as string;

	const archiveStats = useInfluencerDiscoveryArchiveStats(archiveId);
	useTitle(archiveStats.data ? archiveStats.data.name : "View Archive");

	return (
		<Limit>
			<TitleBar title={archiveStats.data ? archiveStats.data.name : "Loading..."} />

			<SmartBox
				switchOn={archiveStats}
				loading={() => <ContainerSegment><Center><Loader /></Center></ContainerSegment>}
				error={err => <ContainerSegment><ErrorDisplay problemResponse={err} /></ContainerSegment>}
				success={data => <ContentDisplay stats={data} />} />
		</Limit>
	);
};

interface ContentDisplayProps {
	stats: InfluencerDiscoveryArchiveStatsResponseModel;
}

const ContentDisplay = ({ stats }: ContentDisplayProps): JSX.Element => {
	return (
		<Stack>
			<Container>
				<ContainerListItem title="Name">{stats.name}</ContainerListItem>
				<ContainerListItem title="Created">{dayjs(stats.created).format("DD MMM YYYY")}</ContainerListItem>
			</Container>

			<Container>
				{
					(stats.createdCount !== 0 || stats.queryingCount !== 0) &&
					<ContainerSegment>
						<LiveIndicator />
					</ContainerSegment>
				}

				<ContainerSegment>
					<StatsBar {...stats} />
				</ContainerSegment>
				<ContainerListItem title="Waiting">{stats.createdCount}</ContainerListItem>
				<ContainerListItem title="In Progress">{stats.queryingCount}</ContainerListItem>
				<ContainerListItem title="Failed">{stats.errorCount}</ContainerListItem>
				<ContainerListItem title="No Match">{stats.unmatchCount}</ContainerListItem>
				<ContainerListItem title="Matched" subtitle=" ">{stats.matchCount}</ContainerListItem>
			</Container>

			{
				stats.createdCount === 0 && stats.queryingCount === 0 &&
				<Container>
					<ContainerSegment>
						<b>Download Results CSV</b><br />
						This archive has finished processing.  Click the button below to download the resulting CSV.
					</ContainerSegment>
					<ContainerListButton link={`${API_BASE_URL}/admin/influencer-discovery/${stats.archiveId}/csv`} newTab text="Download CSV" />
				</Container>
			}
		</Stack>
	);
};
